import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        outages: null,
        timer: 0,
        message: null,
        oldMessage: null,
    },
    mutations: {},
    actions: {},
    modules: {},
})
