<template>
    <div class="account-outage">
        <div class="account-outage--header">
            <div class="account-outage--title">
                <h3>{{ outage.town.toLowerCase() }}</h3>
            </div>
            <div
                class="outage-details--status"
                v-if="outage.status.toLowerCase() != 'restored'"
            >
                <div class="outage-details--status-indicator">
                    <span
                        :data-status="statusIndicatorColor(outage.percentOut)"
                        class="outage-details--status-indicator-circle"
                    ></span>
                    <span
                        class="outage-details--status-indicator-text"
                        :data-status="statusIndicatorColor(outage.percentOut)"
                        >{{ statusIndicatorText(outage.status) }}</span
                    >
                </div>
            </div>
            <div class="outage-details--status" v-else>
                <span class="outage-details--status-indicator" data-status="0"
                    ><svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="check"
                        class="svg-inline--fa fa-check fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
                        ></path>
                    </svg>
                    Restored</span
                >
            </div>
        </div>

        <div class="outage-details-data">
            <div class="outage-details-data--affected" v-if="outage.numout">
                <div class="outage-details-data--value">
                    <p>{{ outage.numout }}</p>
                </div>
                <div class="outage-details-data--label">
                    <p>
                        {{ outage.numout > 1 ? 'Members' : 'Member' }}
                        Affected
                    </p>
                </div>
            </div>
            <div class="outage-details-data--percent" v-if="outage.percentOut">
                <div class="outage-details-data--value">
                    <p>{{ outage.percentOut }}%</p>
                </div>
                <div class="outage-details-data--label">
                    <p>
                        of
                        <span class="outage-details-data--label_town">{{
                            outage.town.toLowerCase()
                        }}</span>
                        Affected
                    </p>
                </div>
            </div>
            <div class="outage-details-data--cause" v-if="outage.cause">
                <div class="outage-details-data--value">
                    <p>{{ outage.cause }}</p>
                </div>
                <div class="outage-details-data--label">
                    <p>Cause</p>
                </div>
            </div>
            <div
                class="outage-details-data--description"
                v-if="outage.description"
            >
                <div class="outage-details-data--value">
                    <p>{{ outage.description }}</p>
                </div>
                <div class="outage-details-data--label">
                    <p>Description</p>
                </div>
            </div>
            <div class="outage-details-data--etr" v-if="!outage.time_on">
                <div
                    class="outage-details-data--value"
                    v-if="formatDate(outage.etr)"
                >
                    <p>
                        {{ formatDate(outage.etr).month }}
                        {{ formatDate(outage.etr).day }},
                        {{ formatDate(outage.etr).hours }}:{{
                            formatDate(outage.etr).minutes
                        }}
                        {{ formatDate(outage.etr).meridiem }}
                    </p>
                </div>
                <div class="outage-details-data--value" v-else>
                    <p>Unknown</p>
                </div>
                <div class="outage-details-data--label">
                    <p>Estimated Restore Time</p>
                </div>
            </div>
            <div class="outage-details-data--time-on" v-else>
                <div
                    class="outage-details-data--value"
                    v-if="formatDate(outage.time_on)"
                >
                    <p>
                        {{ formatDate(outage.time_on).month }}
                        {{ formatDate(outage.time_on).day }},
                        {{ formatDate(outage.time_on).hours }}:{{
                            formatDate(outage.time_on).minutes
                        }}
                        {{ formatDate(outage.time_on).meridiem }}
                    </p>
                </div>
                <div class="outage-details-data--value" v-else>
                    <p>Unknown</p>
                </div>
                <div class="outage-details-data--label">
                    <p>Time On</p>
                </div>
            </div>
            <div class="outage-details-data--time-off">
                <div
                    class="outage-details-data--value"
                    v-if="formatDate(outage.time_off)"
                >
                    <p>
                        {{ formatDate(outage.time_off).month }}
                        {{ formatDate(outage.time_off).day }},
                        {{ formatDate(outage.time_off).hours }}:{{
                            formatDate(outage.time_off).minutes
                        }}
                        {{ formatDate(outage.time_off).meridiem }}
                    </p>
                </div>
                <div class="outage-details-data--value" v-else>
                    <p>Unknown</p>
                </div>
                <div class="outage-details-data--label">
                    <p>Time Off</p>
                </div>
            </div>
            <div class="outage-details-data--id">
                <div class="outage-details-data--value">
                    <p>#{{ outage.tk }}</p>
                </div>
            </div>
        </div>

        <!-- <pre><code>{{ outage }}</code></pre> -->
    </div>
</template>

<script>
import { computeDates } from '@/components/computeDates.js'
import { outageValue } from '@/components/computeOutageValue.js'

export default {
    name: 'AccountOutges',
    props: {
        outage: Object
    },
    methods: {
        formatDate(date) {
            return computeDates.formatDate(date)
        },
        statusIndicatorColor(value) {
            return outageValue.outageValueNumber(value)
        },
        statusIndicatorText(value) {
            return outageValue.outageValueText(value)
        }
    }
}
</script>

<style lang="scss">
.account-outage {
    flex-flow: row wrap;
    width: 100%;
    text-align: left;
    padding: 1.25em;
    margin-bottom: 1.5em;
    border-radius: 0.5em;
    background: #fff;
    transition: 140ms ease;
    box-shadow: 0px 2px 5px 0px rgba(50, 50, 93, 0.09),
        0px 1px 2px 0px rgba(0, 0, 0, 0.07);

    @media (max-width: 768px) {
        width: 100%;
    }

    &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .outage-details-data {
        padding: 0 1em;
    }

    &--status {
        margin-left: 1em;

        &-indicator {
            display: inline-flex;
            padding: 0.35em 0.75em;
            color: #fff;
            text-transform: uppercase;
            border-radius: 0.75em;
            font-weight: 600;

            svg {
                width: 1em;
                margin-right: 0.5em;
            }

            &[data-status='0'] {
                background: rgba($color-status-0, 0.2);
                color: $color-status-0;
            }

            &[data-status='1'] {
                background: rgba($color-status-1, 0.2);
                color: $color-status-1;
            }

            &[data-status='2'] {
                background: rgba($color-status-2, 0.2);
                color: $color-status-2;
            }

            &[data-status='3'] {
                background: rgba($color-status-3, 0.2);
                color: $color-status-3;
            }

            &[data-status='4'] {
                background: rgba($color-status-4, 0.2);
                color: $color-status-4;
            }
        }
    }

    &--title {
        text-transform: capitalize;
        padding: 0 1rem;
        margin: 0.75em 0;
        color: $color-0;

        h3,
        h4 {
            color: $color-0;
            margin-bottom: 0;
        }

        @media (max-width: 640px) {
            font-size: 2rem;
        }
    }
}
</style>
