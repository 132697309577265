<template>
    <div class="report-outage-panel">
        <button
            name="Close Report Window"
            class="report-outage-panel--exit"
            @click="closeReportPanel"
        >
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
            >
                <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                ></path>
            </svg>
        </button>

        <div class="container">
            <h2 class="report-outage-panel--title">Report an Outage</h2>
            <p>
                To report an outage, please call us, or sign in to your SmartHub
                account.
            </p>
            <div class="buttons">
                <a
                    href="https://vermontelectric.smarthub.coop/Login.html"
                    target="_blank"
                    class="button"
                    >My SmartHub Account</a
                >
                <a href="tel:8026352331" class="button">Call (802) 635-2331</a>
                <a href="tel:8008322667" class="button"
                    >Toll Free (800) 832-2667</a
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportOutage',
    props: {
        status: Boolean
    },
    methods: {
        closeReportPanel() {
            this.$emit('closeReportPanel')
        }
    }
}
</script>

<style lang="scss">
.report-outage-panel {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: rgba(47, 52, 55, 0.25);
    background: rgba(246, 249, 252, 0.75);
    box-shadow: $box-shadow-1;
    z-index: 99;
    animation: report-panel-enter 200ms ease forwards;

    @keyframes report-panel-enter {
        from {
            tansform: translateY(3em);
            backdrop-filter: blur(0);
        }
        to {
            transform: trnaslateY(0);
            backdrop-filter: blur(12px);
        }
    }

    &--exit {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1em;
        right: 1em;
        width: 4em;
        height: 4em;
        padding: 0.5em;
        background: $color-1;
        color: #fff;
        border-radius: 50%;

        svg {
            width: 2em;
            height: 2em;
        }
    }

    .container {
        max-width: 860px;
        text-align: center;

        h2 {
            text-align: center;
            margin-bottom: 0.5em;
        }
    }

    .buttons {
        justify-content: center;

        .button {
            margin-top: 0.5em;
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
    }
}
</style>
