import {
    format,
    differenceInMinutes,
    formatDistance,
    formatDistanceStrict,
    formatDuration,
    addMinutes,
    parseISO,
    intervalToDuration,
    isValid
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { te } from 'date-fns/locale'

export const computeDates = {
    //Previously used for visual timer but not defunct by formatTime function
    // addMinutes(minutes) {
    //     const d = new Date()
    //     const futureDate = addMinutes(d, minutes)
    //     const futureHours = format(futureDate, 'h')
    //     const futureMinutes = format(futureDate, 'mm')
    //     return `${futureHours}<span class="timer-blinker">:</span>${futureMinutes}`
    // },
    formatDate(date) {
        if (date) {
            const d = new Date(date.replace(/-/g, '/')) //replace / for iOS
            if (isValid(d)) {
                return {
                    weekday: format(d, 'EEEE'),
                    month: format(d, 'LLLL'),
                    day: format(d, 'd'),
                    dayio: format(d, 'do'),
                    year: format(d, 'yyyy'),
                    hours: format(d, 'h'),
                    minutes: format(d, 'mm'),
                    meridiem: format(d, 'aaaa'),
                    full: format(d, 'LLLL d, h:mm aaaa')
                }
            }
        }
    },
    formatTime(time) {
        const t = intervalToDuration({ start: 0, end: time * 1000 })
        let m = t.minutes,
            s = t.seconds

        if (s < 10) {
            s = `0${s}`
        }
        return `${m}:${s}`
    },
    formatDateAgo(date) {
        const d = new Date(date.replace(/-/g, '/')) //must specify string timezone at the end of the date for localization to work -- replace / for iOS
        if (isValid(d)) {
            const dz = d.toISOString()

            //convert var date + time into localized UTC time
            const formattedDate = formatInTimeZone(
                dz,
                'yyyy-MM-dd kk:mm:ss xxx'
            )

            //convert current date + tme into localized UTC Time
            const formattedNewDate = formatInTimeZone(
                new Date().toISOString(),
                'yyyy-MM-dd kk:mm:ss xxx'
            )

            function formatInTimeZone(date, fmt) {
                return utcToZonedTime(date, 'UTC')
            }

            const minutes = Math.abs(
                differenceInMinutes(formattedDate, formattedNewDate)
            )

            //calculate the distance between the var date and the current date
            const pastDate = formatDistance(formattedDate, formattedNewDate, {
                includeSeconds: true
            })
            return pastDate.replace('about ', '') + ' ago'
        } else {
            return ''
        }
    },
    formatDateNow(date) {
        const d = new Date(date.replace(/-/g, '/')) //must specify string timezone at the end of the date for localization to work -- replace / for iOS
        if (isValid(d)) {
            const dz = d.toISOString()

            //convert var date + time into localized UTC time
            const formattedDate = formatInTimeZone(
                dz,
                'yyyy-MM-dd kk:mm:ss xxx'
            )

            //convert current date + tme into localized UTC Time
            const formattedNewDate = formatInTimeZone(
                new Date().toISOString(),
                'yyyy-MM-dd kk:mm:ss xxx'
            )

            function formatInTimeZone(date, fmt) {
                return utcToZonedTime(date, 'UTC')
            }

            const minutes = Math.abs(
                differenceInMinutes(formattedNewDate, formattedDate)
            )

            if (minutes < 60) {
                return formatDistance(formattedNewDate, formattedDate)
            } else {
                const xyz = formatDistance(formattedNewDate, formattedDate)

                // if it's greater than 23 hours
                if (minutes > 1380) {
                    // if it's greater than 24 hours
                    if (minutes > 1420) {
                        return 'more than 24 hours'
                    } else {
                        return 'about 24 hours'
                    }
                } else {
                    const minutesCompute = minutes % 60
                    return xyz.replace(
                        /hours?/,
                        `hours${
                            minutesCompute > 0
                                ? ', ' + minutesCompute + ' minutes'
                                : ''
                        }`
                    )
                }
            }

            //calculate the distance between the current date, and the var date
            // const futureDate = formatDistance(formattedNewDate, formattedDate)
            // return futureDate
        } else {
            return ''
        }
    }
}
