<template>
    <div class="recent-outage-item">
        <button
            class="recent-outage-item--button"
            name="Open Outage Details"
            @click="emitOutageDetails"
        >
            <div class="recent-outage-item--town">
                <h4>{{ outage.town.toLowerCase() }}</h4>
            </div>
            <!-- <div class="recent-outage-item--status">
                <span class="recent-outage-item--status-indicator">
                    {{ outage.status }}
                </span>
            </div> -->
            <div class="recent-outage-item--time">
                <p>
                    {{ formatDateAgo(outage.time_on) }}
                    <span>{{ outage.status.toLowerCase() }}</span>
                </p>
            </div>
        </button>
    </div>
</template>

<script>
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { computeDates } from '@/components/computeDates.js'

export default {
    name: 'RecentOutagesItem',
    props: {
        outage: Object
    },
    methods: {
        emitOutageDetails() {
            this.$emit('clicked', this.outage)
        },
        formatDateAgo(date) {
            return computeDates.formatDateAgo(date)
        }
    }
}
</script>

<style lang="scss">
.recent-outage-item {
    margin-bottom: 0.1em;

    p {
        font-size: 1rem;
    }

    &--button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        padding: 0.5em;
        border-radius: 0.5em;

        &:hover,
        &:focus {
            background: $color-grey-2;
            outline: none;
        }
    }

    &--town {
        flex: 3;
        text-transform: capitalize;

        p {
            font-weight: 600;
        }
    }

    &--time {
        span {
            display: inline-flex;
            padding: 0.25em 0.75em;
            color: #fff;
            text-transform: uppercase;
            border-radius: 0.75em;
            font-size: 0.75rem;
            font-weight: 600;
            background: rgba($color-0, 0.15);
            color: $color-0;
            margin-left: 0.5em;
            line-height: 1.5;
        }
    }
}
</style>
