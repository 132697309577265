<template>
    <!-- <p>{{ timer <= 0 ? '0' : timer }}</p> -->
    <p>
        {{ formatTime(localTimer) }}
    </p>
</template>

<script>
import { computeDates } from '@/components/computeDates.js'

export default {
    name: 'Timer',
    props: {
        timer: Number,
        interval: Number,
        timestamp: String
    },
    data() {
        return {
            localTimer: null
        }
    },
    computed: {
        localTime: {
            get() {
                return this.timer
            },
            set(value) {
                this.localTimer = value
            }
        }
    },
    mounted() {
        this.localTime = this.interval

        setInterval(() => {
            if (this.localTimer <= 0) {
                return
            }

            this.localTimer -= 1
            this.$store.state.timer = this.localTimer
        }, 1000)
    },
    methods: {
        formatTime(value) {
            return computeDates.formatTime(value)
        }
    },
    watch: {
        '$store.state.outages': function() {
            this.localTime = this.interval
        }
    }
}
</script>
