export const outageValue = {
    outageValueNumber: function(value) {
        switch (true) {
            case value == 0:
                return 0
                break
            case value > 0 && value <= 25:
                return 1
                break
            case value > 25.01 && value <= 50:
                return 2
                break
            case value > 50.01 && value <= 75:
                return 3
                break
            case value > 75.01 && value <= 100:
                return 4
                break
        }
    },
    outageValueText: function(value) {
        // switch (true) {
        //     case value == 0:
        //         return 'Open'
        //         break
        //     case value > 0 && value <= 25:
        //         return 'Low'
        //         break
        //     case value > 25.01 && value <= 50:
        //         return 'Medium'
        //         break
        //     case value > 50.01 && value <= 75:
        //         return 'High'
        //         break
        //     case value > 75.01 && value <= 100:
        //         return 'Severe'
        //         break
        // }
        return value
    }
}
