<template>
    <div class="outages-item" ref="outage">
        <button
            class="outages-item--button"
            @click="emitOutageDetails"
            name="Open Outage Details"
        >
            <div class="outages-item--town">
                <h4>
                    {{ outage.town.toLowerCase() }}
                    <span class="outages-item--town-id">#{{ outage.tk }}</span>
                </h4>
            </div>
            <div class="outages-item--status">
                <span class="outages-item--status-indicator" :data-status="0"
                    >{{
                        outage.numout > 1
                            ? outage.numout + ' Members'
                            : outage.numout + ' Member'
                    }}
                    Affected</span
                >
            </div>
            <!-- <div class="outages-item--affected">
                <p class="outages-item-datapoint">
                    <abbr
                        :aria-label="
                            outage.percentOut
                                ? outage.percentOut + '% of town'
                                : ''
                        "
                        :data-tooltip="
                            outage.percentOut
                                ? outage.percentOut + '% of town'
                                : ''
                        "
                        >{{ outage.numout }}</abbr
                    >
                    <span>Members affected</span>
                </p>
            </div> -->
            <div class="outages-item--time-off">
                <p class="outages-item-datapoint">
                    <abbr
                        :aria-label="formatDate(outage.time_off).full"
                        :data-tooltip="formatDate(outage.time_off).full"
                        >{{ formatDateAgo(outage.time_off) }}</abbr
                    >
                    <span>Time Off</span>
                </p>
            </div>
            <div class="outages-item--etr">
                <p class="outages-item-datapoint">
                    <abbr
                        :data-label="
                            outage.etr ? formatDate(outage.etr).full : 'Unknown'
                        "
                        :data-tooltip="
                            outage.etr ? formatDate(outage.etr).full : 'Unknown'
                        "
                        >{{
                            outage.etr ? formatDateNow(outage.etr) : 'Unknown'
                        }}</abbr
                    >
                    <span>Estimated Restore Time</span>
                </p>
            </div>
        </button>
    </div>
</template>

<script>
import { outageValue } from '@/components/computeOutageValue.js'
import { computeDates } from '@/components/computeDates.js'

export default {
    name: 'CurrentOutagesItem',
    props: {
        outage: Object
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        emitOutageDetails() {
            this.$emit('clicked', this.outage)
        },
        emitOutageHoverDetails(value) {
            if (!value) {
                this.$emit('hovered', null)
            } else {
                this.$emit('hovered', this.outage)
            }
        },
        formatDate(date) {
            return computeDates.formatDate(date)
        },
        formatDateAgo(date) {
            return computeDates.formatDateAgo(date)
        },
        formatDateNow(date) {
            return computeDates.formatDateNow(date)
        },
        statusIndicatorColor(value) {
            return outageValue.outageValueNumber(value)
        },
        statusIndicatorText(value) {
            return outageValue.outageValueText(value)
        }
    },
    mounted() {}
}
</script>

<style lang="scss">
.outages-item {
    margin-bottom: 0.65em;
    user-select: none;

    p {
        font-size: 1rem;
    }

    &--button {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-flow: row wrap;
        width: 100%;
        text-align: left;
        padding: 1.25em;
        border-radius: 0.5em;
        background: #fff;
        transition: 140ms ease;
        box-shadow: $box-shadow-1;

        &:hover {
            box-shadow: $box-shadow-2;
            outline: none;
            transform: translateY(-0.125em);

            .outages-item--town-id {
                opacity: 0.5;
            }

            @media (max-width: 768px) {
                transform: none;
            }
        }

        @media (max-width: 450px) {
            flex-flow: column nowrap;
            align-items: flex-start;
        }
    }

    &--status {
        //width: 100%;
        width: 40%;
        text-align: right;
        margin-bottom: 0.25em;

        @media (max-width: 450px) {
            width: 100%;
            text-align: left;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }

        &-indicator {
            display: inline-flex;
            padding: 0.3em 0.75em;
            color: #fff;
            //text-transform: uppercase;
            border-radius: 0.75em;
            font-size: 0.875rem;
            //font-weight: 600;
            width: max-content;

            &[data-status='0'] {
                background: rgba($color-status-0, 0.15);
                color: $color-status-0;
                border-color: $color-status-0;
            }

            &[data-status='1'] {
                background: rgba($color-status-1, 0.15);
                color: $color-status-1;
                border-color: $color-status-1;
            }

            &[data-status='2'] {
                background: rgba($color-status-2, 0.15);
                color: $color-status-2;
                border-color: $color-status-2;
            }

            &[data-status='3'] {
                background: rgba($color-status-3, 0.15);
                color: $color-status-3;
                border-color: $color-status-3;
            }

            &[data-status='4'] {
                background: rgba($color-status-4, 0.15);
                color: $color-status-4;
                border-color: $color-status-4;
            }
        }
    }

    &--town {
        display: flex;
        align-items: center;
        width: 60%;
        text-transform: capitalize;
        font-size: 1.3rem;

        span {
            font-size: 1rem;
            font-weight: 400;
            font-family: monospace;
            opacity: 0;
            transition: 140ms ease;
            display: none;
        }

        @media (max-width: 450px) {
            width: 100%;
        }
    }

    &--affected {
        @media (max-width: 500px) {
            display: none;
        }
    }

    &--time-off {
        //width: calc(40% - 12px);
        //text-align: right;
    }

    &--affected {
        margin-top: 1em;
    }

    &--percent {
        margin-top: 1em;
    }

    &--etr {
        margin-top: 1em;
        text-align: right;

        @media (max-width: 450px) {
            text-align: left;
            margin-top: 0.5em;
        }
    }

    &-datapoint {
        line-height: 1.2;

        > abbr {
            display: inline-block;
            padding: 0.125em 0.125em 2px;
            margin-bottom: 0.25em;
        }

        > span {
            display: block;
        }
    }

    span:not([class]) {
        font-size: 0.75rem;
        opacity: 0.6;

        b {
            font-weight: 400;
            text-transform: capitalize;
        }
    }

    &--no-outages {
        padding: 1.25em;
        border-radius: 0.5em;
        transition: 140ms ease;
        text-align: center;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-icon {
            width: 3em;
            opacity: 0.5;
            margin: 1em auto;
        }

        &-descriptor {
            font-weight: 600;
        }
    }
}
</style>
