<template>
    <section class="account-panel">
        <button
            name="Close Account Window"
            class="account-panel--exit"
            @click="closeAccountPanel"
        >
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
            >
                <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                ></path>
            </svg>
        </button>

        <Loader v-if="loading" text="Looking up your account..." />

        <div class="overflow" v-else>
            <div class="container">
                <!-- search account number -->
                <div class="account-search" v-if="stage == 1">
                    <h2 class="account-panel--title">
                        Search for your account
                    </h2>
                    <div class="account-panel--description">
                        <p>
                            Enter your Vermont Electric account number. <br />
                            Your account number must contain only numbers.
                        </p>
                    </div>
                    <form v-on:submit.prevent="accountLookup">
                        <label for="accountNumber" class="visually-hidden"
                            >Account Number</label
                        >
                        <input
                            id="accountNumber"
                            type="number"
                            placeholder="Account number..."
                            v-model="accountNumber"
                            @keyup="inputFeedback"
                            pattern="[0-9]*"
                            inputmode="numeric"
                        />
                        <input type="submit" value="Search" />
                    </form>
                    <p class="account-panel--error" v-if="accountError">
                        {{ accountErrorMessage }}
                    </p>
                </div>

                <div class="account-panel--results" v-else>
                    <!-- if account exists -->
                    <div class="account-outages" v-if="accountOutages">
                        <!-- if there are outages, show them -->
                        <div v-if="accountOutages">
                            <!-- No outages were found -->
                            <div v-if="accountOutages.length == 0">
                                <div class="account-panel--title">
                                    <h3 class="account-panel--outages">
                                        No outages affecting you.
                                    </h3>
                                    <p>
                                        If you are experiencing a problem,
                                        please
                                        <a
                                            target="_blank"
                                            href="https://vermontelectric.coop/contact"
                                            >contact us</a
                                        >.
                                    </p>
                                </div>
                                <br />
                                <div class="buttons buttons-center">
                                    <a
                                        href="#"
                                        @click.prevent="closeAccountPanel"
                                        class="button"
                                        >Close</a
                                    >
                                </div>
                            </div>

                            <!-- Outages were found -->
                            <div v-else>
                                <div class="account-panel--title">
                                    <h3 class="account-panel--outages">
                                        {{ accountOutages.length }}
                                        outage{{
                                            accountOutages.length > 1 ? 's' : ''
                                        }}
                                        in your area.
                                    </h3>
                                </div>
                                <div class="account-outages-group">
                                    <AccountOutages
                                        v-for="outage in accountOutages"
                                        :key="outage.id"
                                        :outage="outage"
                                    />
                                </div>
                                <p class="account-panel--account">
                                    Account Number: {{ accountNumber }}
                                </p>
                            </div>
                        </div>

                        <!-- if there are no outages, show default message -->
                        <div class="account-panel--results-no-results" v-else>
                            <p>No outages affecting you.</p>
                        </div>
                    </div>

                    <!-- if account doesnt exist, or errors out upon lookup -->
                    <div class="account-panel--results-error" v-else>
                        <h2>Something went wrong...</h2>
                        <p>
                            We could not find your account. Please
                            <a href="#" @click.prevent="resetStage">try again</a
                            >, or <a href="#">contact us</a>.
                            <br />
                            <br />
                            <a href="#" @click.prevent="resetStage">Return</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-else>
            <div class="container" v-if="outages != ''">
                <div class="account-panel--title">
                    <h3 class="account-panel--outages">
                        {{ outages.length }} outage{{
                            outages.length > 1 ? 's' : ''
                        }}
                        in your area.
                    </h3>
                </div>
                <AccountOutages
                    v-for="outage in outages"
                    :key="outage.id"
                    :outage="outage"
                />
                <p class="account-panel--account">{{ accountNumber }}</p>
            </div>
            <div v-else>
                <h2>Something went wrong...</h2>
                <p>
                    We could not find your account. Please try again, or
                    <a href="#">contact us</a>.
                </p>
            </div>
        </div> -->
    </section>
</template>

<script>
import Loader from '@/components/Loader'
import AccountOutages from '@/components/AccountOutages'

export default {
    name: 'AccountPanel',
    props: {
        outages: Array,
        status: Boolean,
    },
    components: {
        Loader,
        AccountOutages,
    },
    data() {
        return {
            loading: false,
            stage: 1,
            accountURL:
                'https://vermontelectric.coop/custom/new_version/get_data.php',
            accountNumber: '',
            accountOutages: null,
            accountOutagesStatus: false,
            accountError: false,
            accountErrorMessage: '',
        }
    },
    methods: {
        accountLookup() {
            if (this.accountError) {
                return false
            }
            if (this.accountNumber.length < 1) {
                this.inputFeedback()
                return false
            }

            this.loading = true

            let formData = new FormData()
            formData.append('account', this.accountNumber)

            if (accountNumber) {
                this.stage = 2
                console.log('Data Sent:')
                console.log(this.accountNumber)
                this.axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    url: this.accountURL,
                    data: formData,
                })
                    .then(response => {
                        console.log('Data Recieved:')
                        console.log(response.data)
                        this.accountOutages = response.data.towns
                        this.accountOutageStatus = true
                    })
                    .catch(err => {
                        this.accountOutageStatus = false
                        console.error(err)
                    })
                setTimeout(() => {
                    this.loading = false
                }, 1500)
            }
        },
        inputFeedback() {
            console.log(this.accountNumber)
            if (this.accountNumber.length < 1) {
                this.accountError = true
                this.accountErrorMessage = 'Please enter your account number.'
                return false
            }
            this.accountError = false
        },
        resetStage() {
            this.accountOutages = null
            this.stage = 1
        },
        closeAccountPanel() {
            this.$emit('closeAccountPanel')
        },
    },
    mounted() {
        // this.loading = true
        // setTimeout(() => {
        //     this.loading = false
        // }, 1500)
    },
}
</script>

<style lang="scss">
.account-panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: rgba(47, 52, 55, 0.25);
    background: rgba(246, 249, 252, 0.75);
    box-shadow: $box-shadow-1;
    z-index: 99;
    animation: account-panel-enter 200ms ease forwards;

    @keyframes account-panel-enter {
        from {
            tansform: translateY(3em);
            backdrop-filter: blur(0);
        }
        to {
            transform: trnaslateY(0);
            backdrop-filter: blur(12px);
        }
    }

    &--exit {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1em;
        right: 2em;
        width: 4em;
        height: 4em;
        padding: 0.5em;
        background: $color-1;
        color: #fff;
        border-radius: 50%;
        z-index: 99;

        @media (max-width: 768px) {
            right: 1em;
        }

        svg {
            width: 2em;
            height: 2em;
        }
    }

    &--error {
        text-align: center;
        color: #eb3535;
    }

    &--title {
        margin-bottom: 2em;
        text-align: center;
    }

    &--description {
        margin-bottom: 1em;
        text-align: center;
        opacity: 0.8;
    }

    &--account {
        font-size: 0.875rem;
        opacity: 0.6;
        text-align: center;
        padding-bottom: 2em;
    }

    &--results {
        &-no-results,
        &-error {
            text-align: center;
        }
    }

    .overflow {
        max-height: 100vh;
        overflow: auto;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container {
        max-width: 860px;
        max-height: 100vh;
        padding: 6em 1em 3em;

        h2 {
            text-align: center;
        }
    }

    form {
        display: flex;
        align-items: center;
        margin-right: 2em;
        width: 540px;
        margin: 0 auto 10vh;

        @media (max-width: 1600px) {
            //width: 275px;
        }
        @media (max-width: 1300px) {
            //width: 340px;
        }
        @media (max-width: 968px) {
            max-width: 450px;
            width: 100%;
        }
        @media (max-width: 768px) {
            flex-direction: column;
        }

        label {
            display: block;
            margin: 1em 0 0.25em;
        }
        input[type='text'],
        input[type='number'] {
            max-width: 800px;
            width: 100%;
            padding: 0.65em;
            color: $color-0;
            background: #fff;
            border: 2px solid $color-grey-2;
            border-radius: 0.25em 0 0 0.25em;
            font-family: inherit;
            font-size: 1.25rem;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            margin: 0;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type='submit'] {
            background: #c1d82f;
            padding: 0.85em 1.25em;
            border-radius: 0 0.25em 0.25em 0;
            color: #fff;
            margin-left: -1px;

            @media (max-width: 768px) {
                margin-left: 0;
                width: 100%;
                margin-top: 1em;
                font-weight: 600;
                font-size: 1.125rem;
                border-radius: 0.25em;
            }
        }
    }
}
</style>
