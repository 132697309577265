<template>
    <div class="map">
        <l-map ref="map" :zoom="zoom" :center="center">
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-geo-json
                v-if="loading"
                ref="geojson"
                :geojson="geojson"
                :options="options"
                :options-style="styleFunction"
            />
            <div class="map-static-tooltip">
                <p>{{ tooltipContent }}</p>
            </div>
            <l-control position="bottomleft">
                <div class="map-legend">
                    <div class="map-legend-title">Percent of town</div>
                    <div class="map-legend-item">
                        <span
                            data-status="1"
                            class="map-legend-item--swatch"
                        ></span>
                        <p class="map-legend-item--label">> 0 - 25%</p>
                    </div>
                    <div class="map-legend-item">
                        <span
                            data-status="2"
                            class="map-legend-item--swatch"
                        ></span>
                        <p class="map-legend-item--label">> 25 - 50%</p>
                    </div>
                    <div class="map-legend-item">
                        <span
                            data-status="3"
                            class="map-legend-item--swatch"
                        ></span>
                        <p class="map-legend-item--label">> 50 - 75%</p>
                    </div>
                    <div class="map-legend-item">
                        <span
                            data-status="4"
                            class="map-legend-item--swatch"
                        ></span>
                        <p class="map-legend-item--label">> 75 - 100%</p>
                    </div>
                </div>
            </l-control>
        </l-map>
    </div>
</template>

<script>
import L from 'leaflet'
import { latLng } from 'leaflet'
import {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    LPopup,
    LControl,
} from 'vue2-leaflet'

export default {
    name: 'Map',
    components: {
        LMap,
        LTileLayer,
        LGeoJson,
        LMarker,
        LPopup,
        LControl,
    },
    props: {
        outages: Array,
        selectedOutage: Object,
        hoveredOutage: Object,
    },
    data() {
        return {
            loading: false,
            show: true,
            enableTooltip: true,
            tooltipContent: '',
            zoom: 9,
            maxZoom: 12,
            center: [44.6, -72.4275],
            geojson: null,
            fillColor: 'transparent',
            url: 'https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
            attribution: '<a href="http://osm.org/copyright">OpenStreetMap</a>',
            serviceTowns: null,
            serviceTownsURL:
                'https://vermontelectric.coop/custom/new_version/vt-electric-service-towns.geojson',
            countyCoordinates: [],
            previouslySelectedLayer: null,
            previouslyHoveredLayer: null,
        }
    },
    created() {},
    mounted() {
        if (window.matchMedia('(max-width: 786px)').matches) {
            this.zoom = 8
        }
    },
    async created() {
        this.updateGeoJSONData()
    },
    computed: {
        options() {
            return {
                onEachFeature: this.onEachFeatureFunction,
            }
        },
        styleFunction() {
            //Used to style the geojson
            const fillColor = this.fillColor
            return () => {
                return {
                    fillColor: '#0082c8',
                    fillOpacity: 0.1,
                }
            }
        },
        onEachFeatureFunction() {
            let self = this

            return (feature, layer) => {
                //sets the tooltip template for the tooltip hovers on the map
                let tooltipTemplate = `<div>${feature.properties.townMC}</div>`
                //sets the popup template for the popups on map interaction with a layer
                let popupTemplate = `<div>
                        <div class="leaflet-map--town"><h4>${feature.properties.town.toLowerCase()}</h4></div>
                        ${
                            feature.properties.outagesCount
                                ? `<div class="leaflet-map--outages">${feature.properties.outagesCount}</div>`
                                : ''
                        }
                        <div class="leaflet-map--affected">${
                            feature.properties.numOut
                        } members affected.</div>
                        <div class="leaflet-map--percent">${
                            feature.properties.percentOut
                        }% of ${feature.properties.townMC} affected.</div>
                    </div>`

                //adds a tooltip + data to each layer
                // if (self.enableTooltip) {
                //     layer.bindTooltip(tooltipTemplate, {
                //         permanent: false,
                //         sticky: false
                //     })
                // }

                //adds a popup + data to each layer
                layer.bindPopup(popupTemplate)

                //sets the style when you mouseover a layer
                layer.on('mouseover', function (e) {
                    e.target.setStyle({
                        fillOpacity: 0.8,
                    })
                    self.tooltipContent = feature.properties.townMC
                })

                //sets the style when you leave mouseover of a layer
                layer.on('mouseout', function (e) {
                    if (feature.properties.percentOut > 0) {
                        e.target.setStyle({
                            fillOpacity: 0.5,
                        })
                    } else {
                        e.target.setStyle({
                            fillOpacity: 0.1,
                        })
                    }
                    self.tooltipContent = ''
                })

                //shows a popup on click
                layer.on('click', function (e) {
                    // layer.openPopup({
                    //     offset: [0, 0]
                    // })
                    self.enableTooltip = false
                })

                //customizes view of layer when added to map
                layer.on('add', function (e) {
                    let percent = feature.properties.percentOut

                    function computeOutageSeverityColor(value) {
                        switch (true) {
                            case value == 0:
                                return '#0082c8'
                                break
                            case value > 0 && value <= 25:
                                return '#fbc224'
                                break
                            case value > 25.01 && value <= 50:
                                return '#fba524'
                                break
                            case value > 50.01 && value <= 75:
                                return '#fb7e24'
                                break
                            case value > 75.01:
                                return '#dc2626'
                                break
                        }
                    }

                    //sets the fillcolor based on the outage severity
                    e.target.setStyle({
                        fillColor: computeOutageSeverityColor(percent),
                    })

                    //sets the fill opacity based on if it has an outage or not (boldens outage layers)
                    if (feature.properties.percentOut > 0) {
                        e.target.setStyle({
                            fillOpacity: 0.5,
                        })
                    }

                    //compiles town information for later interactions via selectedOutage
                    let layerObject = {
                        town: feature.properties.town,
                        id: e.target._leaflet_id,
                    }

                    self.countyCoordinates.push(layerObject)
                })
            }
        },
    },
    methods: {
        statusIndicatorText(value) {
            return outageValue.outageValueText(value)
        },
        updateGeoJSONData() {
            this.loading = true

            //get the service towns data
            this.axios(this.serviceTownsURL)
                .then(response => {
                    const data = response.data
                    this.serviceTowns = data

                    //parse and merge current outages with the static geojson data
                    const outages = this.outages
                    const serviceTowns = this.serviceTowns

                    serviceTowns.features.forEach(item => {
                        const town = item.properties.town.toLowerCase()

                        //find current outage matches for the individual towns
                        const matches = outages.filter(outage => {
                            return outage.town.toLowerCase().includes(town)
                        })

                        let numOut = 0
                        let percentOut = 0

                        //sum up current outage numbers based on the town
                        matches.forEach(match => {
                            numOut += +match.numout
                            percentOut += +match.percentOut
                        })

                        //round the percentage if it isn't 0
                        if (percentOut > 0) {
                            percentOut = percentOut.toFixed(2)
                        }

                        //count and total outages for tooltip and popup reference
                        let outageCount
                        if (matches.length == 1) {
                            outageCount = '1 outage.'
                        }
                        if (matches.length < 1 || matches.length > 1) {
                            outageCount = matches.length + ' outages.'
                        }

                        //band aid fix for percentOut if > 100%
                        //issue arose 4/19/22 when they had massive outages
                        //percentOut for outages added up to greater than 100 and therefore is not possible
                        //adding fix to default to 100 if > 100% to not show incorrect data
                        if (percentOut > 100) {
                            percentOut = 100
                        }

                        //populate the values into the geojson for map render
                        item.properties.outagesCount = outageCount
                        item.properties.numOut = numOut
                        item.properties.percentOut = percentOut
                    })

                    this.geojson = serviceTowns
                    this.loading = true
                })
                .catch(err => {
                    console.error(err)
                    this.loading = true
                })
        },
    },
    watch: {
        '$store.state.outages': function () {
            this.updateGeoJSONData()
        },
        hoveredOutage(value) {
            if (this.previouslyHoveredLayer) {
                //reset the layer styling
                this.previouslyHoveredLayer.setStyle({
                    color: '#0082c8',
                    weight: 3,
                })
            }

            if (value) {
                //check is there is a matching county for layers based on the layer name
                const matchingCounty = this.countyCoordinates.filter(county => {
                    return county.town == value.town
                })

                if (matchingCounty.length == 1) {
                    let c = matchingCounty[0]

                    //sets the selected layer styling
                    this.$refs.map.mapObject._layers[c.id].setStyle({
                        weight: 5,
                        color: '#222222',
                    })

                    //sets the selected layer to be brought to the highest z index
                    this.$refs.map.mapObject._layers[c.id].bringToFront()

                    //sets the previously selected layer so we can undo styles when deselected
                    this.previouslyHoveredLayer =
                        this.$refs.map.mapObject._layers[c.id]
                }
            }
        },
        selectedOutage(value) {
            //style for a deselected/normal layer
            const defaultStyling = {
                color: '#0082c8',
                weight: 2,
                fillOpacity: 0.5,
            }

            //style for a selected/focused layer
            const selectedStyling = {
                color: '#222222',
                weight: 6,
                fillOpacity: 0.8,
            }

            //if there's no selected layer, reset the map and layer styling
            if (!value) {
                // this.$refs.map.mapObject.setZoom(9)

                this.$nextTick(() => {
                    //recenters the map and attempts sets the zoom
                    let zoomCorrection = 9
                    if (window.matchMedia('(max-width: 986px)').matches) {
                        zoomCorrection = 8
                    }

                    this.$refs.map.mapObject.setView(
                        {
                            lat: 44.6,
                            lng: -72.5778,
                        },
                        zoomCorrection
                    )
                    if (this.previouslySelectedLayer) {
                        //reset the layer styling
                        this.previouslySelectedLayer.setStyle(defaultStyling)

                        //close any old popups
                        this.previouslySelectedLayer.closePopup()
                    }
                })
                return false
            }

            if (value.status.toLowerCase() == 'restored') {
                return false
            }

            //check is there is a matching county for layers based on the layer name
            const matchingCounty = this.countyCoordinates.filter(county => {
                return county.town == value.town
            })

            if (matchingCounty.length == 1) {
                this.$nextTick(() => {
                    let c = matchingCounty[0]

                    //reset style of last selected layer
                    if (this.previouslySelectedLayer) {
                        this.previouslySelectedLayer.setStyle(defaultStyling)
                    }

                    this.$refs.map.mapObject._layers[c.id].openPopup()

                    //zooms the map to the calculcated boundings of the selected layer
                    this.$refs.map.mapObject.fitBounds(
                        this.$refs.map.mapObject._layers[c.id]._bounds,
                        { maxZoom: 10 }
                    )

                    //sets the selected layer styling
                    this.$refs.map.mapObject._layers[c.id].setStyle(
                        selectedStyling
                    )

                    //sets the selected layer to be brought to the highest z index
                    this.$refs.map.mapObject._layers[c.id].bringToFront()

                    //sets the previously selected layer so we can undo styles when deselected
                    this.previouslySelectedLayer =
                        this.$refs.map.mapObject._layers[c.id]
                })
            }
        },
    },
}
</script>

<style lang="scss">
.map {
    width: calc(100vw - 500px);
    height: calc(100vh - 6em);
    position: relative;
    margin-top: 6em;
    overflow: hidden;
    user-select: none;

    @media (max-width: 1400px) {
        width: calc(100vw - 350px);
    }
    @media (max-width: 1100px) {
        width: 100vw;
        max-height: calc(100vh - 6em - 3.5em);
        margin-top: 0;
    }
    @media (max-width: 968px) {
        width: 100vw;
        max-height: calc(99vh - 4.5em - 3.5em - 3em);
    }

    .leaflet-container {
        z-index: 1;
    }

    path.leaflet-interactive {
        transition: fill-opacity 90ms ease;
    }

    .leaflet-tooltip,
    .leaflet-popup {
        font-family: inherit;
        font-size: 1rem;

        .leaflet-map {
            &--town {
                text-transform: capitalize;
            }
        }
    }

    .leaflet-control-attribution {
        padding: 0.5em;
        border-radius: 0.5em;
        margin: 0.25em;
        bottom: 0.75em;
        right: 0.75em;
        background: rgba(255, 255, 255, 0.8) !important;
        box-shadow: $box-shadow-1;
        display: flex;
        align-items: flex-end;
        user-select: none;

        a {
            font-size: 0.75rem;
            color: $color-0;
            margin: 0 0.25em;
        }
    }

    &-static-tooltip {
        position: absolute;
        z-index: 999;
        bottom: 0.5em;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 1rem;
        margin: 0 auto;
        user-select: none;

        @media (max-width: 768px) {
            display: none;
        }

        p {
            display: inline-block;
            border-radius: 0.25em;
            padding: 0.5em 1em;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: $box-shadow-1;
            animation: tooltip-fade-up 60ms ease forwards;

            @keyframes tooltip-fade-up {
                from {
                    opacity: 0;
                    transform: translate(0, 0.25em);
                }
                to {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }

            &:empty {
                display: none;
            }
        }
    }

    &-legend {
        background: rgba(255, 255, 255, 0.8);
        box-shadow: $box-shadow-1;
        padding: 1em;
        border-radius: 0.5em;
        user-select: none;

        @media (max-width: 968px) {
            //display: none;
        }

        &-title {
            margin-bottom: 0.5em;
            font-weight: 600;
            font-size: 0.75rem;
        }

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 0.25em;

            &--swatch {
                display: block;
                width: 1em;
                height: 1em;
                margin-right: 0.75em;
                border-radius: 0.125em;

                &[data-status='0'] {
                    background: $color-status-0;
                }
                &[data-status='1'] {
                    background: $color-status-1;
                }
                &[data-status='2'] {
                    background: $color-status-2;
                }
                &[data-status='3'] {
                    background: $color-status-3;
                }
                &[data-status='4'] {
                    background: $color-status-4;
                }
            }

            &--label {
                font-size: 0.75rem;
                margin-bottom: 0;
                line-height: 1;
            }
        }
    }
}
</style>
