require('es6-object-assign').polyfill() //IE Polyfill for object.assign()
require('date-time-format-timezone') //IE Polyfill for TimeZone conversions

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import 'leaflet/dist/leaflet.css'
import Promise from 'promise-polyfill'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
    config: { id: 'UA-168287-4' }
})
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
    store,
    render: h => h(App)
}).$mount('#app')
