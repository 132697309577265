<template>
    <div
        class="mobile-menu"
        :class="{ 'mobile-menu--active': status }"
        ref="mobileMenu"
    >
        <button
            name="Close Mobile Menu Window"
            class="mobile-menu--exit"
            @click="closeMobileMenu"
        >
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
            >
                <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                ></path>
            </svg>
        </button>

        <div class="container">
            <div class="return">
                <a href="https://vermontelectric.coop/">
                    <img
                        src="@/assets/VEC-logo-refresh.svg"
                        alt="Vermont Electric Coop Logo"
                    />
                </a>
            </div>

            <!-- outage stats -->
            <div class="group">
                <div class="outage-stats-item">
                    <div class="outage-stats-item--value">
                        <p>
                            {{
                                outages.current_outage.total_outages
                                    ? outages.current_outage.total_outages
                                    : 0
                            }}
                        </p>
                    </div>
                    <div class="outage-stats-item--label">Outages</div>
                </div>
                <div class="outage-stats-item">
                    <div class="outage-stats-item--value">
                        <p>
                            {{
                                outages.current_outage.members_affected
                                    ? outages.current_outage.members_affected
                                    : 0
                            }}
                        </p>
                    </div>
                    <div class="outage-stats-item--label">Members affected</div>
                </div>
                <div class="outage-stats-item">
                    <div class="outage-stats-item--value">
                        <p>
                            {{
                                outages.current_outage.towns_affected
                                    ? outages.current_outage.towns_affected
                                    : 0
                            }}
                        </p>
                    </div>
                    <div class="outage-stats-item--label">Towns affected</div>
                </div>

                <!-- Timer -->
                <div class="outage-stats-item">
                    <div class="outage-stats-item--value">
                        <p>
                            {{ formatTime(this.$store.state.timer) }}
                        </p>
                    </div>
                    <div class="outage-stats-item--label">Next Update</div>
                </div>
            </div>

            <!-- actions -->
            <button
                @click="openAlertPanel"
                class="button button-2 button-size-small"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                    <path
                        fill="currentColor"
                        d="M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z"
                    />
                </svg>
                <span>Alert</span>
            </button>

            <button @click="searchAccount" class="button button-size-small">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="search"
                    class="svg-inline--fa fa-search fa-w-16"
                    role="img"
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="currentColor"
                        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
                    />
                </svg>
                <span>Search your account</span>
            </button>
            <button
                @click="reportOutageStatus"
                class="button button-3 button-size-small"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="bolt"
                    class="svg-inline--fa fa-bolt fa-w-10"
                    role="img"
                    viewBox="0 0 320 512"
                >
                    <path
                        fill="currentColor"
                        d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z"
                    />
                </svg>
                <span>Report an Outage</span>
            </button>
        </div>
    </div>
</template>

<script>
import Timer from '@/components/Timer.vue'
import { computeDates } from '@/components/computeDates.js'

export default {
    name: 'MobileMenu',
    props: {
        updateInterval: Number,
        outages: Object,
        status: Boolean,
    },
    components: {
        Timer,
    },
    methods: {
        closeMobileMenu() {
            this.$emit('closeMobileMenu')
        },
        formatDate(date) {
            return computeDates.formatDate(date)
        },
        formatTime(value) {
            return computeDates.formatTime(value)
        },
        searchAccount() {
            this.$emit('setAccountOutageStatus')
        },
        reportOutageStatus() {
            this.$emit('setReportOutageStatus')
        },
        openAlertPanel() {
            this.$emit('setAlertPanelStatus')
        },
    },
    mounted() {
        this.$refs.mobileMenu.querySelector('.mobile-menu--exit').focus()
    },
}
</script>

<style lang="scss">
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(3px);
    box-shadow: 0px 15px 35px 0px rgba(50, 50, 93, 0.1),
        0px 5px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 0.5em;
    padding-bottom: 2em;
    z-index: 99;
    animation: mobile-menu-inactive 160ms ease forwards;

    @keyframes mobile-menu-inactive {
        from {
            transform: translate(0, 0);
        }
        to {
            transform: translate(0, -105%);
        }
    }

    &--active {
        animation: mobile-menu-active 160ms ease forwards;
        @keyframes mobile-menu-active {
            from {
                transform: translate(0, -105%);
            }
            to {
                transform: translate(0, 0%);
            }
        }
    }

    &--exit {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1em;
        right: 2em;
        width: 4em;
        height: 4em;
        padding: 0.5em;
        background: $color-1;
        color: #fff;
        border-radius: 50%;
        z-index: 99;

        @media (max-width: 768px) {
            right: 1em;
        }

        svg {
            width: 2em;
            height: 2em;
        }
    }

    .return {
        max-width: 150px;
        margin-right: 4em;
    }

    .group {
        margin: 1.5em -1em;
        text-align: center;
    }

    .outage-stats-item {
        width: calc(50% - 2em);
        margin: 1em;
    }

    .button,
    .button + .button {
        margin-left: 0;
        margin-top: 0.5em;
        width: 100%;
        text-align: center;
        justify-content: center;
        font-weight: 500;
    }
}
</style>
