<template>
    <div
        class="outage-details-panel"
        v-if="outage"
        :class="{ 'outage-details-panel--active': outage }"
    >
        <Loader v-if="loading" />
        <section
            class="outage-details"
            :class="{ 'outage-details--loaded': !loading }"
            v-else
        >
            <div class="outage-details-header">
                <div
                    class="outage-details--status"
                    v-if="outage.status.toLowerCase() != 'restored'"
                >
                    <div class="outage-details--status-indicator">
                        <span
                            :data-status="
                                statusIndicatorColor(outage.percentOut)
                            "
                            class="outage-details--status-indicator-circle"
                        ></span>
                        <span
                            class="outage-details--status-indicator-text"
                            :data-status="
                                statusIndicatorColor(outage.percentOut)
                            "
                            >{{ statusIndicatorText(outage.status) }}</span
                        >
                    </div>
                </div>
                <div class="outage-details--status" v-else>
                    <span
                        class="outage-details--status-indicator"
                        data-status="0"
                        ><svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="check"
                            class="svg-inline--fa fa-check fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
                            ></path>
                        </svg>
                        Restored</span
                    >
                </div>
                <button
                    type="button"
                    name="Close Outage Details"
                    class="outage-details-header--exit"
                    @click="deselectOutage"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="times"
                        class="svg-inline--fa fa-times fa-w-10"
                        role="img"
                        viewBox="0 0 320 512"
                    >
                        <path
                            fill="currentColor"
                            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                        />
                    </svg>
                </button>
            </div>
            <div class="container">
                <h3 class="outage-details--title">
                    {{ outage.town.toLowerCase() }}
                </h3>

                <div class="outage-details-data">
                    <div class="outage-details-data-group">
                        <div
                            class="outage-details-data--affected"
                            v-if="outage.numout"
                        >
                            <div class="outage-details-data--value">
                                <p>{{ outage.numout }}</p>
                            </div>
                            <div class="outage-details-data--label">
                                <p>
                                    {{
                                        outage.numout > 1 ? 'Members' : 'Member'
                                    }}
                                    Affected
                                </p>
                            </div>
                        </div>
                        <div
                            class="outage-details-data--percent"
                            v-if="outage.percentOut"
                        >
                            <div class="outage-details-data--value">
                                <p>{{ outage.percentOut }}%</p>
                            </div>
                            <div class="outage-details-data--label">
                                <p>
                                    of
                                    <span
                                        class="outage-details-data--label_town"
                                        >{{ outage.town.toLowerCase() }}</span
                                    >
                                    Affected
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="outage-details-data--cause" v-if="outage.cause">
                        <div class="outage-details-data--value">
                            <p>{{ outage.cause }}</p>
                        </div>
                        <div class="outage-details-data--label">
                            <p>Cause</p>
                        </div>
                    </div>
                    <div
                        class="outage-details-data--description"
                        v-if="outage.description"
                    >
                        <div class="outage-details-data--value">
                            <p>{{ outage.description }}</p>
                        </div>
                        <div class="outage-details-data--label">
                            <p>Description</p>
                        </div>
                    </div>
                    <div
                        class="outage-details-data--etr"
                        v-if="!outage.time_on"
                    >
                        <div
                            class="outage-details-data--value"
                            v-if="formatDate(outage.etr)"
                        >
                            <p>
                                {{ formatDate(outage.etr).month }}
                                {{ formatDate(outage.etr).day }},
                                {{ formatDate(outage.etr).hours }}:{{
                                    formatDate(outage.etr).minutes
                                }}
                                {{ formatDate(outage.etr).meridiem }}
                            </p>
                        </div>
                        <div class="outage-details-data--value" v-else>
                            <p>Unknown</p>
                        </div>
                        <div class="outage-details-data--label">
                            <p>Estimated Restore Time</p>
                        </div>
                    </div>
                    <div class="outage-details-data--time-on" v-else>
                        <div
                            class="outage-details-data--value"
                            v-if="formatDate(outage.time_on)"
                        >
                            <p>
                                {{ formatDate(outage.time_on).month }}
                                {{ formatDate(outage.time_on).day }},
                                {{ formatDate(outage.time_on).hours }}:{{
                                    formatDate(outage.time_on).minutes
                                }}
                                {{ formatDate(outage.time_on).meridiem }}
                            </p>
                        </div>
                        <div class="outage-details-data--value" v-else>
                            <p>Unknown</p>
                        </div>
                        <div class="outage-details-data--label">
                            <p>Time On</p>
                        </div>
                    </div>
                    <div class="outage-details-data--time-off">
                        <div
                            class="outage-details-data--value"
                            v-if="formatDate(outage.time_off)"
                        >
                            <p>
                                {{ formatDate(outage.time_off).month }}
                                {{ formatDate(outage.time_off).day }},
                                {{ formatDate(outage.time_off).hours }}:{{
                                    formatDate(outage.time_off).minutes
                                }}
                                {{ formatDate(outage.time_off).meridiem }}
                            </p>
                        </div>
                        <div class="outage-details-data--value" v-else>
                            <p>Unknown</p>
                        </div>
                        <div class="outage-details-data--label">
                            <p>Time Off</p>
                        </div>
                    </div>
                    <div class="outage-details-data--id">
                        <div class="outage-details-data--value">
                            <p>#{{ outage.tk }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { computeDates } from '@/components/computeDates.js'
import { outageValue } from '@/components/computeOutageValue.js'
import Loader from '@/components/Loader'

export default {
    name: 'OutageDetailPanel',
    data() {
        return {
            loading: true
        }
    },
    props: {
        outage: Object
    },
    components: {
        Loader
    },
    methods: {
        formatDate(date) {
            return computeDates.formatDate(date)
        },
        deselectOutage() {
            this.$emit('deselectOutage')
        },
        statusIndicatorColor(value) {
            return outageValue.outageValueNumber(value)
        },
        statusIndicatorText(value) {
            return outageValue.outageValueText(value)
        }
    },
    watch: {
        outage() {
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 450)
        }
    }
}
</script>

<style lang="scss">
.outage-details-panel {
    position: absolute;
    top: 6em;
    bottom: 0;
    right: 500px;
    width: 400px;
    max-height: 100vh;
    background: $color-grey-1;
    box-shadow: $box-shadow-1;
    z-index: 1;
    overflow: auto;
    animation: outage-details-panel-inactive 160ms ease forwards;

    @media (max-width: 1400px) {
        right: 400px;
        width: 350px;
    }

    @media (max-width: 1100px) {
        position: fixed;
        width: 100%;
        top: 6em;
        left: 0;
        z-index: 2;
    }
    @media (max-width: 968px) {
        top: 4.5em;
    }

    @keyframes outage-details-panel-inactive {
        from {
            transform: translate(0%, 0);
        }
        to {
            transform: translate(105%, 0);
        }
    }

    &--active {
        animation: outage-details-panel-active 160ms ease forwards;

        @media (max-width: 1100px) {
            animation: outage-details-panel-active-up 160ms ease forwards;
        }

        @keyframes outage-details-panel-active {
            from {
                transform: translate(105%, 0);
            }
            to {
                transform: translate(0%, 0);
            }
        }
        @keyframes outage-details-panel-active-up {
            from {
                transform: translate(0, 105%);
            }
            to {
                transform: translate(0, 0%);
            }
        }
    }
}

.outage-details {
    opacity: 0;

    &--loaded {
        animation: outage-details-loaded 140ms ease forwards;

        @keyframes outage-details-loaded {
            from {
                opacity: 0;
                transform: translate(0, 0.25em);
            }
            to {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }

    .container {
        padding: 0;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-bottom: 2px solid $color-grey-2;
        padding: 1em 0;
        height: 3.5em;
        position: sticky;
        top: 0;
        z-index: 2;

        p {
            padding-left: 1rem;
            opacity: 0.75;
        }

        &--exit {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5em;
            margin-right: 0.5em;
            opacity: 0.65;
            transition: 140ms ease;

            &:hover,
            &:focus {
                opacity: 1;
            }

            svg {
                width: 1.25em;
            }
        }
    }

    &--status {
        width: 100%;
        margin-left: 1.5em;

        &-indicator {
            display: inline-flex;
            align-items: center;
            padding: 0.45em 0.75em;
            text-transform: uppercase;
            border-radius: 0.5em;
            font-weight: 600;
            background: rgba(82, 95, 127, 0.15);

            &-circle {
                display: inline-flex;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: red;
                margin-right: 0.5em;
                box-shadow: $box-shadow-1;
            }

            &-text {
                font-weight: 600;
                text-transform: uppercase;
                display: inline-flex;
                color: #fff;
                text-transform: uppercase;
                font-size: 0.875rem;
                font-weight: 600;
                letter-spacing: 0.025em;
                color: #525f7f;
                line-height: 1.5;
            }

            svg {
                width: 1em;
                margin-right: 0.5em;
            }

            &[data-status='0'] {
                background: rgba($color-status-0, 0.2);
                color: $color-status-0;
                border-color: $color-status-0;
            }

            &-circle[data-status='1'] {
                background: $color-status-1;
                color: $color-status-1;
                border-color: $color-status-1;
            }

            &-circle[data-status='2'] {
                background: $color-status-2;
                color: $color-status-2;
                border-color: $color-status-2;
            }

            &-circle[data-status='3'] {
                background: $color-status-3;
                color: $color-status-3;
                border-color: $color-status-3;
            }

            &-circle[data-status='4'] {
                background: $color-status-4;
                color: $color-status-4;
                border-color: $color-status-4;
            }
        }
    }

    &--title {
        text-transform: capitalize;
        padding: 0 1.5rem;
        margin: 1em 0;
        color: $color-0;

        @media (max-width: 640px) {
            font-size: 2rem;
        }
    }

    &-data {
        padding: 0 1.5em;

        &-group {
            display: flex;
            align-items: flex-end;

            > div {
                flex: 1;
            }
        }

        &--status {
            display: inline-flex;
            background: green;
            color: #fff;
            padding: 0.35em 0.75em;
            border-radius: 5em;
            text-transform: uppercase;
            font-size: 0.875rem;
            margin-right: 0.75em;
        }

        &--cause,
        &--description,
        &--etr,
        &--time-off,
        &--time-on,
        &--id,
        &--percent,
        &--affected {
            margin-top: 1.5em;
        }

        &--value {
            p {
                font-size: 1.25rem;

                span {
                    opacity: 0.65;
                }
            }
        }

        &--label {
            p {
                font-size: 0.875rem;
                opacity: 0.6;
            }

            &_town {
                text-transform: capitalize;
            }
        }

        &--id {
            .outage-details-data--value p {
                display: inline-flex;
                padding: 0.3em 0.75em;
                color: #fff;
                text-transform: uppercase;
                border-radius: 0.5em;
                font-size: 1rem;
                font-weight: 600;
                background: rgba($color-0, 0.15);
                color: $color-0;
            }
        }
    }

    &-datapoint {
        padding: 1em;

        p {
            margin-bottom: 0;
        }

        &--value {
            text-transform: capitalize;
        }

        &--label {
            font-size: 1rem;
            opacity: 0.75;
        }
    }
}
</style>
