<template>
    <section class="alert-panel" v-if="alertMessage" ref="alertPanel">
        <button
            name="Close Account Window"
            class="alert-panel--exit"
            @click="closeAlertPanel"
        >
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
            >
                <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                ></path>
            </svg>
        </button>

        <div class="container">
            <h2>Alert</h2>
            <div v-html="alertMessage"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AlertPanel',
    props: {
        alertMessage: String,
    },
    methods: {
        closeAlertPanel() {
            this.$emit('closeAlertPanel')
        },
        openAlertPanel() {
            this.$emit('openAlertPanel')
        },
    },
    created() {},
    mounted() {
        if (this.alertMessage) {
            this.$refs.alertPanel.focus()
        }
    },
    watch: {
        alertMessage: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                // compare old message in state to new message

                if (this.$store.state.oldMessage != val) {
                    console.log('NEW ALERT MESSAGE in Alert Panel')
                    console.log(val)
                    console.log(this.$store.state.oldMessage)
                    this.openAlertPanel()
                    this.$refs.alertPanel.focus()
                }
            },
        },
    },
}
</script>

<style lang="scss">
.alert-panel {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    //background: rgba(47, 52, 55, 0.25);
    background: rgba(246, 249, 252, 0.75);
    box-shadow: $box-shadow-1;
    border-radius: 0.5em;
    z-index: 99;
    animation: account-panel-enter 200ms ease forwards;
    transform: translateY(-50%);

    @media (max-width: 968px) {
        top: 0;
        bottom: 0;
        transform: translateY(0);
        max-width: 100%;
    }

    @keyframes account-panel-enter {
        from {
            tansform: translateY(3em);
            backdrop-filter: blur(0);
        }
        to {
            transform: trnaslateY(0);
            backdrop-filter: blur(12px);
        }
    }

    &--exit {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1em;
        right: 1em;
        width: 4em;
        height: 4em;
        padding: 0.5em;
        background: $color-1;
        color: #fff;
        border-radius: 50%;
        z-index: 99;

        @media (max-width: 768px) {
            right: 1em;
        }

        svg {
            width: 2em;
            height: 2em;
        }
    }

    .container {
        max-width: 860px;
        max-height: 100vh;
        padding: 6em 1em 6em;
        text-align: center;

        h2 {
            text-align: center;
        }
    }
}
</style>
