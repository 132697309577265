<template>
    <div id="app">
        <div class="vt-electric-outages" v-if="appLoading">
            <Map
                :outages="outages.current_outage.towns"
                :selectedOutage="selectedOutage"
                :hoveredOutage="hoveredOutage"
                v-if="!screenSizeMobile"
            />

            <MobileMenu
                @closeMobileMenu="closeMobileMenu"
                @setAccountOutageStatus="setAccountOutageStatus"
                @setReportOutageStatus="setReportOutageStatus"
                @setAlertPanelStatus="setAlertStatus"
                v-if="mobileMenuStatus"
                :updateInterval="updateInterval"
                :outages="outages"
                :status="mobileMenuStatus"
            />

            <AlertPanel
                @closeAlertPanel="closeAlertPanel"
                @openAlertPanel="openAlertPanel"
                v-show="alertStatus"
                :alertMessage="alertMessage"
            />

            <header class="outages-header">
                <div class="container">
                    <div class="group">
                        <div class="return">
                            <a href="https://vermontelectric.coop/">
                                <img
                                    src="@/assets/VEC-logo-refresh.svg"
                                    alt="Vermont Electric Coop Logo"
                                />
                            </a>
                        </div>
                        <div class="outage-stats">
                            <div class="outage-stats-item">
                                <div class="outage-stats-item--value">
                                    <p>
                                        {{
                                            outages.current_outage.total_outages
                                                ? outages.current_outage
                                                      .total_outages
                                                : 0
                                        }}
                                    </p>
                                </div>
                                <div class="outage-stats-item--label">
                                    Outages
                                </div>
                            </div>
                            <div class="outage-stats-item">
                                <div class="outage-stats-item--value">
                                    <p>
                                        {{
                                            outages.current_outage
                                                .members_affected
                                                ? outages.current_outage
                                                      .members_affected
                                                : 0
                                        }}
                                    </p>
                                </div>
                                <div class="outage-stats-item--label">
                                    Members affected
                                </div>
                            </div>
                            <div class="outage-stats-item">
                                <div class="outage-stats-item--value">
                                    <p>
                                        {{
                                            outages.current_outage
                                                .towns_affected
                                                ? outages.current_outage
                                                      .towns_affected
                                                : 0
                                        }}
                                    </p>
                                </div>
                                <div class="outage-stats-item--label">
                                    Towns affected
                                </div>
                            </div>
                            <div
                                class="outage-stats-item outage-stats-item--timer"
                            >
                                <div class="outage-stats-item--value">
                                    <Timer
                                        v-bind:timer="updateInterval"
                                        v-bind:interval="updateInterval"
                                        :timestamp="
                                            formatDate(
                                                outages.current_outage.updated
                                            ).full
                                        "
                                    />
                                </div>
                                <div class="outage-stats-item--label">
                                    Next Update
                                </div>
                            </div>
                        </div>
                        <div class="group buttons">
                            <button
                                @click="openAlertPanel"
                                class="button button-2 button-size-small"
                                v-if="outages.alert_message"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                                    <path
                                        fill="currentColor"
                                        d="M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z"
                                    />
                                </svg>
                                <span>Alert</span>
                            </button>
                            <button
                                @click="searchAccount"
                                class="button button-size-small"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="far"
                                    data-icon="search"
                                    class="svg-inline--fa fa-search fa-w-16"
                                    role="img"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
                                    />
                                </svg>
                                <span>Search your account</span>
                            </button>
                            <button
                                @click="reportOutageStatus = true"
                                class="button button-3 button-size-small"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="bolt"
                                    class="svg-inline--fa fa-bolt fa-w-10"
                                    role="img"
                                    viewBox="0 0 320 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z"
                                    />
                                </svg>
                                <span>Report an Outage</span>
                            </button>
                        </div>
                        <div class="mobile-navicon">
                            <button
                                class="mobile-navicon-button"
                                @click="openMobileMenu"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    data-icon="bars"
                                    class="svg-inline--fa fa-bars fa-w-14"
                                    role="img"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            <div class="outages">
                <div class="outages-actions">
                    <button
                        type="button"
                        name="Show Current Outages"
                        class="outages-actions-button"
                        :class="{
                            'outages-actions-button--active':
                                selectedOutagePanel == 1,
                        }"
                        @click="updateSelectedOutagePanel(1)"
                    >
                        <span>Current Outages</span>
                    </button>
                    <button
                        type="button"
                        name="Show Recent Outages"
                        class="outages-actions-button"
                        :class="{
                            'outages-actions-button--active':
                                selectedOutagePanel == 2,
                        }"
                        @click="updateSelectedOutagePanel(2)"
                    >
                        <span>Past Outages</span>
                    </button>
                    <button
                        type="button"
                        name="Show Outages Map"
                        class="outages-actions-button outages-actions-button-mobile"
                        :class="{
                            'outages-actions-button--active':
                                selectedOutagePanel == 3,
                        }"
                        @click="updateSelectedOutagePanel(3)"
                    >
                        <span>Map</span>
                    </button>
                </div>
                <section
                    class="outages-current"
                    :class="{
                        'outages-current--active': selectedOutagePanel == 1,
                    }"
                    v-if="selectedOutagePanel == 1"
                >
                    <div class="container">
                        <!-- shows sorting utility for outages if there are current outages -->
                        <div
                            class="outage-utility group"
                            v-if="outages.current_outage.towns.length > 0"
                        >
                            <div class="select">
                                <select
                                    @change="sortOutages"
                                    name="outage-sort"
                                    id="outage-sort"
                                >
                                    <option value="" default selected hidden>
                                        Sort outages...
                                    </option>
                                    <option value="town">Town</option>
                                    <option value="time-off">Time off</option>
                                    <option value="restore-time">
                                        Est. restore time
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- diplays if there are current outages to show -->
                        <div v-if="outages.current_outage.towns.length > 0">
                            <div v-if="outageSortingStatus">
                                <Loader text="Sorting outages..." />
                            </div>
                            <div v-else>
                                <CurrentOutagesItem
                                    v-for="(item, index) in outages
                                        .current_outage.towns"
                                    :key="index"
                                    :outage="item"
                                    @clicked="onSelectedOutage"
                                    @hovered="onHoveredOutage"
                                />
                                <p class="descriptor">
                                    Click an outage for more details.
                                </p>
                            </div>
                        </div>

                        <!-- displays if there are no current outages -->
                        <div
                            class="outages-item outages-item--no-outages"
                            v-else
                        >
                            <div class="outages-item--no-outages-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    data-icon="bolt"
                                    class="svg-inline--fa fa-bolt fa-w-10"
                                    role="img"
                                    viewBox="0 0 320 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.3 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36zM140.3 436.9l33.5-141.6 9.3-39.4h-150L63 32h125.9l-38.7 118-13.8 42h145.7L140.3 436.9z"
                                    />
                                </svg>
                            </div>
                            <p c class="outages-item--no-outages-descriptor">
                                No current outages.
                            </p>
                        </div>
                    </div>
                </section>

                <section
                    class="outages-recent"
                    :class="{
                        'outages-recent--active': selectedOutagePanel == 2,
                    }"
                    v-if="selectedOutagePanel == 2"
                >
                    <div class="container">
                        <!-- displays if there are past outages to show -->
                        <div v-if="outages.archive_data.length >= 1">
                            <RecentOutagesItem
                                v-for="(item, index) in outages.archive_data"
                                :key="index"
                                :outage="item"
                                @clicked="onSelectedOutage"
                            />
                            <p class="descriptor">
                                Outages in the last 24 hours.<br />Click an
                                outage for more details.
                            </p>
                        </div>

                        <!-- displays if there are NOT past outages to show -->
                        <div
                            class="outages-item outages-item--no-outages"
                            v-else
                        >
                            <div class="outages-item--no-outages-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    data-icon="bolt"
                                    class="svg-inline--fa fa-bolt fa-w-10"
                                    role="img"
                                    viewBox="0 0 320 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.3 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36zM140.3 436.9l33.5-141.6 9.3-39.4h-150L63 32h125.9l-38.7 118-13.8 42h145.7L140.3 436.9z"
                                    />
                                </svg>
                            </div>
                            <p c class="outages-item--no-outages-descriptor">
                                No recent outages.
                            </p>
                        </div>
                    </div>
                </section>

                <section
                    class="outages-map"
                    :class="{
                        'outages-recent--active': selectedOutagePanel == 3,
                    }"
                    v-if="selectedOutagePanel == 3"
                >
                    <Map
                        :outages="outages.current_outage.towns"
                        :selectedOutage="selectedOutage"
                        :hoveredOutage="hoveredOutage"
                        v-if="screenSizeMobile"
                    />
                </section>
            </div>

            <OutageDetailPanel
                :outage="selectedOutage"
                @deselectOutage="deselectOutage"
            />

            <AccountPanel
                :outages="outages.current_outage.towns"
                @closeAccountPanel="closeAccountPanel"
                v-if="accountOutageStatus"
            />

            <ReportOutage
                :status="reportOutageStatus"
                @closeReportPanel="closeReportPanel"
                v-if="reportOutageStatus"
            />
        </div>
        <Loader
            class="vt-electric-outages--loading"
            text="Loading Outages..."
            v-bind:logo="true"
            v-else
        />
        <div class="vt-electric-outages--error" v-if="appError">
            <div class="vt-electric-outages--error-logo">
                <a href="https://vermontelectric.coop/">
                    <img
                        src="@/assets/VEC-logo-refresh.svg"
                        alt="Vermont Electric Coop Logo"
                    />
                </a>
            </div>
            <h2>Something went wrong...</h2>
            <br />
            <p v-html="appErrorMessage"></p>
        </div>
    </div>
</template>

<script>
import CurrentOutagesItem from '@/components/CurrentOutagesItem.vue'
import RecentOutagesItem from '@/components/RecentOutagesItem.vue'
import OutageDetailPanel from '@/components/OutageDetailPanel.vue'
import AccountPanel from '@/components/AccountPanel.vue'
import ReportOutage from '@/components/ReportOutage.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import AlertPanel from '@/components/AlertPanel.vue'
import Loader from '@/components/Loader.vue'
import Timer from '@/components/Timer.vue'
import Map from '@/components/Map.vue'
import { computeDates } from '@/components/computeDates.js'

export default {
    name: 'App',
    components: {
        CurrentOutagesItem,
        RecentOutagesItem,
        OutageDetailPanel,
        AccountPanel,
        AlertPanel,
        ReportOutage,
        MobileMenu,
        Loader,
        Timer,
        Map,
    },
    data() {
        return {
            screenSizeMobile: false,
            selectedOutagePanel: 1,
            selectedOutage: null,
            hoveredOutage: null,
            accountOutageStatus: false,
            alertStatus: false,
            reportOutageStatus: false,
            mobileMenuStatus: false,
            outages: null,
            outageSortingStatus: false,
            timer: '',
            updateInterval: 300,
            appLoading: false,
            appError: false,
            appErrorMessage: null,
            outagesURL: 'https://vermontelectric.coop/custom/new_version/vt_coop_outage.json',
            accountURL:
                'https://vermontelectric.coop/custom/new_version/get_data.php',
        }
    },
    computed: {
        alertMessage() {
            return this.$store.state.outages.alert_message
        },
    },
    methods: {
        openMobileMenu() {
            this.mobileMenuStatus = true
        },
        closeMobileMenu() {
            this.mobileMenuStatus = false
        },
        onSelectedOutage(value) {
            this.selectedOutage = value
        },
        onHoveredOutage(value) {
            this.hoveredOutage = value
        },
        deselectOutage() {
            this.selectedOutage = null
        },
        closeAccountPanel() {
            this.accountOutageStatus = false
        },
        openAlertPanel() {
            this.alertStatus = true
        },
        closeAlertPanel() {
            this.alertStatus = false
        },
        closeReportPanel() {
            this.reportOutageStatus = false
        },
        formatDate(date) {
            return computeDates.formatDate(date)
        },
        updateSelectedOutagePanel(panelNumber) {
            this.selectedOutagePanel = panelNumber
        },
        setAlertStatus() {
            //this is for MobileMenu interaction
            this.alertStatus = true
            this.mobileMenuStatus = false
        },
        setAccountOutageStatus() {
            //this is for MobileMenu interaction
            this.accountOutageStatus = true
            this.mobileMenuStatus = false
        },
        setReportOutageStatus() {
            //this is for MobileMenu interaction
            this.reportOutageStatus = true
            this.mobileMenuStatus = false
        },
        fetchOutages(url) {
            //added timestamp to fetch to make sure it's an uncached response
            let timestamp = +new Date()

            let response = this.axios
                .get(this.outagesURL + '?timestamp=' + timestamp)
                .then(response => {
                    this.$store.state.outages = response.data
                })
                .catch(err => {
                    this.appError = true
                    this.appErrorMessage =
                        'Please try again later.<br /> If this problem continues, please <a href="https://vermontelectric.coop/contact">contact us</a>.'
                    clearInterval(this.timer)
                    console.error(err)
                })
            console.log('[' + timestamp + '] Outage data refreshed.')
            if (this.$store.state.message) {
                console.log('[' + timestamp + '] Alert message refreshed1.')
            }
        },
        cancelAutoUpdate() {
            clearInterval(this.timer)
        },
        sortOutages(e) {
            let sortOption = e.target.value
            let outages = this.outages.current_outage.towns

            //alpha sort the outages by town
            if (sortOption == 'town') {
                let outagesSortedByTown = outages.sort((a, b) => {
                    let aTown = a.town.toUpperCase()
                    let bTown = b.town.toUpperCase()
                    return aTown < bTown ? -1 : aTown > bTown ? 1 : 0
                })
                this.outages.current_outage.towns = outagesSortedByTown
            }

            //chronologically sort the outages by time-off
            if (sortOption == 'time-off') {
                let outagesSortedByTimeOff = outages.sort((a, b) => {
                    let aTime = +new Date(a.time_off)
                    let bTime = +new Date(b.time_off)
                    return aTime > bTime ? -1 : aTime < bTime ? 1 : 0
                })
            }

            //chronologically sort the outages by est restore time
            if (sortOption == 'restore-time') {
                let outagesSortedByRestoreTime = outages.sort((a, b) => {
                    let aTime = +new Date(a.etr)
                    let bTime = +new Date(b.etr)
                    return aTime > bTime ? -1 : aTime < bTime ? 1 : 0
                })
            }

            this.outageSortingStatus = true
            setTimeout(() => {
                this.outageSortingStatus = false
            }, 650)
        },
        searchAccount() {
            this.accountOutageStatus = true
        },
    },
    created() {
        //runs the initial fetching of outages
        this.fetchOutages()
        //sets the interval for updating the fetch for outages
        this.timer = setInterval(this.fetchOutages, this.updateInterval * 1000)
    },
    mounted() {
        this.screenSizeMobile = window.matchMedia('(max-width: 1100px)').matches

        const self = this
        window.addEventListener('resize', () => {
            self.screenSizeMobile = window.matchMedia(
                '(max-width: 1100px)'
            ).matches

            if (
                !window.matchMedia('(max-width: 1100px)').matches &&
                self.selectedOutagePanel == 3
            ) {
                self.selectedOutagePanel = 1
            }
        })

        // if the alert message exists, show the popup
        this.alertStatus = true
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    watch: {
        '$store.state.outages': function () {
            //watches for when the outages state changes, and updates the app accordingly
            this.outages = this.$store.state.outages
            //sets the app state to NOT LOADING to display the information
            this.appLoading = true

            // Set the alert message
            // Set previous message to oldMessage for reference later
            this.$store.state.oldMessage = this.$store.state.message

            // Set new message
            this.$store.state.message = this.$store.state.outages.alert_message
                ? this.$store.state.outages.alert_message
                : null
        },
    },
}
</script>

<style lang="scss">
body {
    padding-top: 0;
}

html {
    scroll-behavior: smooth;
}

#app {
    max-width: 100%;
    padding: 0;
    overflow: hidden;
}

.vt-electric-outages {
    &--loading {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        text-align: center;
        background: $color-grey-1;
    }
    &--error {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        text-align: center;
        background: $color-grey-2;

        &-logo {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            margin-bottom: 3em;

            img {
                width: 100%;
            }
        }
    }
}

.outages-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6em;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(3px);
    box-shadow: 0px 15px 35px 0px rgba(50, 50, 93, 0.1),
        0px 5px 15px 0px rgba(0, 0, 0, 0.07);
    z-index: 3;

    @media (max-width: 968px) {
        height: 4.5em;
    }

    .container {
        max-width: 100%;
        padding: 0.75em 1em;

        @media (max-width: 768px) {
            padding: 0.8em 1em;
        }
        @media (max-width: 500px) {
            padding-right: 0;
        }
    }

    .group {
        justify-content: space-between;
        align-items: center;
        width: auto;

        @media (max-width: 968px) {
        }
    }

    .buttons {
        margin-top: 0;

        @media (max-width: 768px) {
            display: none;
        }

        .button {
            @media (max-width: 768px) {
                font-size: 0.75rem;
                padding: 1em;
            }
            @media (max-width: 500px) {
                flex-direction: column;
                max-width: 7em;
                padding: 0.5em 0;
                background: none;
                color: #006195;
                margin: 0;
                font-weight: 600;

                &.button-3 {
                    color: #fdbc38;
                }

                svg {
                    width: 0.75rem;
                    height: 0.75rem;
                    margin-right: 0;
                    margin-bottom: 0.25em;
                }
            }
        }
    }

    .return {
        width: 100%;
        max-width: 200px;
        text-align: center;

        @media (max-width: 968px) {
            max-width: 130px;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    @media (max-width: 1300px) {
        a.button {
            display: none;
        }
    }
}

.outage-stats {
    display: flex;

    @media (max-width: 1200px) {
        display: none;
    }

    > div {
        margin-left: 3em;
        text-align: center;

        @media (max-width: 1600px) {
            margin-left: 1em;
        }
    }

    &-item {
        &--value {
            margin-bottom: 0.5em;

            p {
                font-size: 2rem;
                line-height: 1.2;

                @media (max-width: 1600px) {
                    font-size: 1.5rem;
                }
            }
        }

        &--label {
            opacity: 0.75;

            @media (max-width: 1600px) {
                font-size: 0.875rem;
            }
        }
    }

    .timer-blinker {
        animation: timer-blinker 2s linear infinite;
        @keyframes timer-blinker {
            0% {
                opacity: 1;
            }
            49% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            99% {
                opacity: 0;
            }
        }
    }
}

.mobile-navicon {
    display: none;
    padding-right: 1em;

    @media (max-width: 768px) {
        display: block;
    }

    &-button {
        width: 2em;
        height: 2em;
    }
}

.outage-utility {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    h4 {
        font-size: 1rem;
        margin-bottom: 0;
        opacity: 0.5;

        &.heading--center {
            text-align: center;
        }
    }
}

.outages {
    position: absolute;
    top: 6em;
    right: 0;
    bottom: 0;
    width: 500px;
    max-height: calc(100vh - 6em);
    overflow: auto;
    background: $color-grey-1;
    box-shadow: $box-shadow-1;
    z-index: 2;

    /* Scrollbar hidden */
    // &::-webkit-scrollbar {
    //     display: none;
    // }
    // -ms-overflow-style: none;
    // scrollbar-width: none;

    @media (max-width: 1400px) {
        width: 400px;
    }
    @media (max-width: 1100px) {
        width: 100%;
        top: 6em;
        max-height: 100vh;
    }
    @media (max-width: 968px) {
        width: 100%;
        top: 4.5em;
    }

    .loader {
        padding: 3em 0;
    }

    .descriptor {
        text-align: center;
        font-size: 0.875rem;
        font-weight: 600;
        opacity: 0.5;
        margin-top: 1em;
        padding-bottom: 1em;
        user-select: none;
    }

    &-actions {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        background: #fff;
        border-bottom: 2px solid $color-grey-2;
        height: 3.5em;
        z-index: 2;
        user-select: none;

        &-button {
            position: relative;
            flex: 1;
            height: 3em;
            padding: 0 0.25em;
            outline: none;
            opacity: 0.45;
            font-weight: 600;
            font-size: 1.125rem;
            transition: 140ms ease;

            @media (max-width: 1500px) {
                font-size: 1rem;
                height: 3.3em;
            }

            @media (max-width: 1100px) {
            }

            @media (max-width: 450px) {
                font-size: 0.875rem;
                height: 3.5em;

                span {
                    display: inline-block;
                    width: min-content;
                }
            }

            &--active {
                color: $color-1;
                opacity: 1;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    right: 0;
                    height: 4px;
                    border-radius: 2px;
                    background: $color-1;
                    z-index: 2;
                }
            }

            &-mobile {
                display: none;

                @media (max-width: 1100px) {
                    display: block;
                }
            }

            &:hover,
            &:focus {
                opacity: 0.7;
            }
        }
    }

    &-current,
    &-recent {
        height: calc(100vh - 6em - 4.5em);

        .container {
            height: 100%;
        }
    }

    &-map {
        margin-bottom: 0;
    }
}

button,
input[type='submit'] {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0;
    cursor: pointer;
}

.button {
    svg {
        font-size: 16px;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5em;
    }
}

abbr {
    position: relative;
    text-decoration: none;
    border-bottom: 2px dotted $color-grey-2;

    &::after {
        position: absolute;
        display: block;
        content: attr(data-tooltip);
        bottom: calc(100% + 0.5em);
        left: 50%;
        max-width: 250px;
        width: max-content;
        padding: 0.5em 0.75em;
        color: #fff;
        font-size: 0.75rem;
        background-image: rgba(49, 49, 47, 0.99);
        background-image: linear-gradient(
            to bottom,
            rgba(49, 49, 47, 0.99),
            #262625
        );
        background-repeat: repeat-x;
        box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
        border-radius: 0.25em;
        transform: translate(-50%, 0);
        z-index: 10;
        display: none;
        opacity: 0;
        pointer-events: none;
    }

    &:hover,
    &:focus {
        &::after {
            display: block;
            animation: hoot-fade-out-top forwards 150ms;
        }
    }

    @keyframes hoot-fade-out-top {
        0% {
            opacity: 0;
            transform: translate(-50%, 3px);
        }
        100% {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }
}

.select {
    position: relative;
    display: inline-flex;
}

.select::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 50%;
    bottom: 0;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    font-family: 'Font Awesome 5 Pro';
    pointer-events: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTMxLjMgMTkyaDI1Ny4zYzE3LjggMCAyNi43IDIxLjUgMTQuMSAzNC4xTDE3NC4xIDM1NC44Yy03LjggNy44LTIwLjUgNy44LTI4LjMgMEwxNy4yIDIyNi4xQzQuNiAyMTMuNSAxMy41IDE5MiAzMS4zIDE5MnoiPjwvcGF0aD48L3N2Zz4=);
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.35;
}

.select select {
    padding: 0.75em;
    padding-right: 2.5em;
    background: none;
    border: 2px solid $color-grey-2;
    border-radius: 0.5em;
    color: $color-0;
    font-weight: 600;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: inherit;
    font-size: 0.875rem;
    user-select: none;

    &:hover,
    &:focus {
        background: rgba(255, 255, 255, 1);
    }
}

.select select::placeholder {
    color: lighten($color-0, 20%);
    overflow: 0.5;
}
</style>
